.icon {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}
/*
[class^="icon-"],
[class*=" icon-"] {
  fill: #1ab394;
  position: relative;
}*/

.icon-no-retoque {
  stroke-width: 1px;
  stroke:  #1ab394;
}

.icon-star-2 {
  fill: gold;
  width: .7rem;
  height: .7rem;
}

.icon-disp {
  fill: #676a6c;
  width: 1.3rem;
  height: 1.3rem;
}


//font awesome
.fa-gift {
  color: pink;
}
