.hideShowBanner,
.hideShowIcono,
.hideShowGal,
.hideShowPortada,
.hideShowHistoria,
.hideShowVideo,
.lock-portada,
.lock-icono,
.lock-gallery2,
.lock-banner,
.lock-historia1,
.lock-historia0,
.lock-video {
  display: none;
}

.lock-gallery2:checked ~ .fa-lock-open {
  display: none;
}

.lock-gallery2:checked ~ .fa-lock {
  display: block;
  color: $navy;
}

.lock-portada:checked ~ .fa-lock-open {
  display: none;
}

.lock-portada:checked ~ .fa-lock {
  display: block;
  color: $navy;
}

.lock-icono:checked ~ .fa-lock-open {
  display: none;
}

.lock-icono:checked ~ .fa-lock {
  display: block;
  color: $navy;
}

.lock-banner:checked ~ .fa-lock-open {
  display: none;
}

.lock-banner:checked ~ .fa-lock {
  display: block;
  color: $navy;
}

.lock-video:checked ~ .fa-lock-open {
  display: none;
}

.lock-video:checked ~ .fa-lock {
  display: block;
  color: $navy;
}

// los colores para el ojo y el candado
.visible-gallery {
  .fa-eye {
    display: none;
    color: var(--green);
    cursor: pointer;
  }
  .fa-eye-slash {
    color: var(--danger);
    cursor: pointer;

  }
}

.lock-gallery {
  .fa-lock {
    display: none;
    color: var(--danger);
  }

  .fa-lock-open {
    color: var(--green);
    cursor: pointer;
  }
}
//fin de los colores

.lock-gallery,
.visible-gallery {
  position: relative;
  display: block;
  margin: 0;
  i {
    margin-right: 5px;
  }
}

//con esto ocultamos el checkbox para que solo se vea el icono del ojo o el candado
$inputGal: (
  "input-date": "visible-gallery",
  "tool-gallery": "lock-gallery",
);

@each $i, $class in $inputGal {
  .#{$i} {
    .#{$class} {
      .icheckbox_square-green {
        display: none;
      }
    }
  }
}

//aqui es la anicmación del icono de bloque de la galeria en fotos

$galcheck: (
  "lock-gallery": (
    "fa-lock",
    "fa-lock-open",
  ),
  "visible-gallery": (
    "fa-eye",
    "fa-eye-slash",
  ),
);

@each $i, $icon in $galcheck {
  $icon1: nth($icon, 2);
  $icon2: nth($icon, 1);

  .#{$i} {
    .icheckbox_square-green.checked ~ {
      .#{$icon1} {
        display: none;
      }
    }
  }

  .#{$i} {
    .icheckbox_square-green.checked ~ {
      .#{$icon2} {
        display: block;
        margin-top: 3px;
      }
    }
  }
}

/* #lock-gallery .icheckbox_square-green:checked ~ .fa-lock {
  display: block;
  color: var(--danger);
}

#lock-gallery  .icheckbox_square-green:checked ~ .fa-lock-open {
  display: none;
}
   */
