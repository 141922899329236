$sizeDeuda: 2.5rem;
$suspender: var(--purple);
$colorBorder: #e7eaec;
$pink: pink;

.transparent {
  color: transparent;
}

#back2Top {
  position: fixed;
  bottom: 20px;
  outline: none;
  right: 30px;
  z-index: 99;
  border: none;
  background-color: #1ab394;
  color: #fff;
  cursor: pointer;
  padding: 15px;
  border-radius: 10px;
}
#back2Top:hover {
  color: white;
  background-color: #189c82;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
}

.transPub {
  display: flex;
  flex-flow: column nowrap;
  &-top {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    &_icon {
      align-items: center;
      display: flex;
      i {
        font-size: 3rem;
      }
    }
  }
  &-bottom {
    margin-top: 2rem;
  }
}

a.btn.btn-xs.btn-default {
  text-indent: 0;
}

.link-micuenta {
  color: #0056b3 !important;
  &:hover {
    color: darken(#0056b3, 5%);
  }
}

.menu-svg {
  padding-left: 20px !important;

  &:hover {
    .icon-disp {
      fill: #1ab394;

    }
  }
}

nav.navbar-default.navbar-static-side {
  @include media('>=768px', 'screen') {
    margin-top: 3.5rem;
  }
}

.none {
  display: none !important;
}

.opacity0 {
  opacity: 0;
}

.botones-home {
  height: 100%;
}

.hr {
  display: block;
  background-color: $colorBorder;
  height: 1px;
  width: 92%;
  margin: 1.5rem auto;

  @include media('>=768px', 'screen') {
    display: none;
  }
}

ul.nav.navbar-top-links.navbar-right {
  margin-right: 1rem;
}
/*
.contenido {
  margin-top: 20px;
}*/

/* #page-wrapper {
  padding: 10px;
}
 */
.navbar.navbar-static-top {
  padding: 0 22px 0 15px;
}

@media (min-width: 768px) {
  .navbar-static-side {
    width: 205px;
  }
}

.pausada {
  background: rgba(red, .2);
}

.bgsuspender {
  background: #1ab394 !important;
  .fa-check {
    color: white;
  }
}

.btn-suspendida {
  background: $suspender;

  &:hover {
    color: white;
    background: #663ab7;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
  }
}

.btn-pack {
  background: white;
  color: darken($pink, 20%);
  font-weight: bold;
  border: 1px solid darken($pink, 2%);
  i {
    color: darken($pink, 20%);
  }
  &:hover {
    color: white;
    background: darken($pink, 10%);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
    i {
      color: white;
    }
  }
}

.label-pack {
  color: white !important;
  background: darken($pink, 20%)!important;
  text-indent: 0;
}


.config-link {
  list-style: none;
  padding: 0;

  &_title {
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
  }

  &_content {
    display: flex;
    flex-direction: column;
    padding: 7px 0px;
    & > a {
      font-size: 14px;
    }
    & > small {
      font-size: 11px;
    }
  }
}
.config-column {
  columns: 2 50px
}

/**
 *
 * INDEX
 *
 */



.seccion-bienvenida {
  h2 {
    font-weight: 300;
  }

  span {
    font-weight: bold;
  }
}


.index-elllasdicen {
  max-width: 200px;
}

.size-deuda {
  font-size: $sizeDeuda;
}

.size-fecha {
  font-size: $sizeDeuda - 1rem;
  font-weight: bold;
}

.bloque-suspension {
  min-height: 12.5rem;
  display: flex;
  flex-direction: column;

  .toggle {
    margin: 0 auto;
  }
}

.info-suspension {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.info-devolucion {
  text-decoration: underline;
  margin-top: auto;
}

.info-dias-devueltos {
  text-align: ce;
  max-width: 80%;
}

.toggle.btn-suspendida.off~.info-suspension {
  display: none;
}

//li para contar los dias suspendidos graficamente
.dias-suspendidos {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 115px;
  margin-top: 1rem;

  li {
    $sizeday: 1rem;
    background: #d0d0d0;
    display: block;
    border-radius: 2px;
    height: $sizeday;
    width: $sizeday;

    &:last-child {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .fa-check {
        font-size: .7rem;
        margin: 0 auto;
      }
    }
  }
}

.datos-publicados,
.config {
  dt,
  dd {
    border-bottom: 1px solid $colorBorder;
    height: 2rem;
    margin-bottom: 0;
    padding: 1.2rem 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  dt {
    font-weight: normal;
    float: left;
    margin-right: .3rem;
  }

  dd {
    font-weight: bold;
    white-space: nowrap;
    text-indent: .5rem;

    .btn-cambiar {
      margin-left: auto;
    }
  }

  &_ubicacion,
  &_tarifa-txt,
  &_promo-txt {
    max-width: 170px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

}

.config {
  &-foto,
  &-shesay,
  &-disponible,
  &-story {
    margin-right: .2rem;
  }
}

.servicios-clientes {
  &_mes,
  &_semana,
  &_hoy {
    display: flex;
    padding: .5rem 0;
    border-bottom: 1px solid $colorBorder;
    width: 100%;
    li {
      margin-right: .5rem;
      span {
        font-weight: bold;
      }
      &:nth-child(2),
      &:nth-child(3) {
        font-weight: bold;
      }
    }
  }
}

.icon::before,
.icon::after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

//modal servicio clientes
#clientesyservicios {
  p {
    color: #3e5476;
  }
  .frase {
    position: relative;
    padding: 1rem;
    &-text {
      text-align: center;
      margin: 0;
      span {
        color: $gray-700 !important;
        font-style: italic;
        font-weight: bold;
        position: relative;
        z-index: 1;
      }

      &::before,
      &::after {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 2rem;
        color: #dedede !important;
        position: absolute;
        z-index: 0;
      }
      &::before {
        content: "\f10d";
        top: 0px;
        left: 10px;
      }
      &::after {
        content: "\f10e";
        bottom: 10px;
        right: 10px;
      }
    }
  }

  .clientesyservicios-list {
    list-style: disc;
    padding-left: 1rem;
    li {
      margin-bottom: .5rem;
    }
  }
}

//modales
.PublicacionExplicacion-body,
.DisponibleComoFunciona-body {
  h2 {
    font-size: 19px;
    font-weight: 700;
  }
  p {
    color: #3e5476 !important;
  }
}

.publicaciones-explicacion,
.publicaciones-devolucion {
  list-style: disc;
  padding-left: 1rem;
  li {
    margin-bottom: .5rem;
  }
}

.ayuda-visual {
  p {
    margin-top: 1rem;
    color: #3e5476 !important;
  }
}





//flo  NEGRA
.flo  -negra_content {

  h5,
  h4,
  small {
    margin: 0 0 .3rem 0;
  }
}

.grid .ibox {
  margin-bottom: 0;

}

.grid {
  @include media('>=667px','screen'){
    column-count: 2;
  }
  @include media('>=1024px','screen'){
    column-count: 3;
  }
  @include media('>=1280px','screen'){
    column-count: 4;
  }
}

// .grid-item {
//   margin-bottom: 25px;
//   width: 300px;
// }

.grid-sizer,
.grid-item {
  width: 100%;
  margin-bottom: 15px;
}


.blacklist-content {
  position: relative;

  &_date {
    position: absolute;
    right: 20px;
  }
  &_title{
    padding-right: 72px;
    margin-top: 0;
  }
  &_phone {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: .8rem;
  }
  &_text {
    border-top: 1px solid $colorBorder;
    padding: .8rem 0;
    margin-bottom: .2rem;
  }
  &_category {
    span {
      font-weight: 600;
    }
  }
  &_ButtonCount {
    display: flex;
    justify-content: flex-end;
  }
  &_count {
    border: 1px solid $colorBorder;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 29px;
    width: 18px;
    p {
      margin: 0;
    }
  }
}


.flo  -negra_txt {
  height: 6rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.slick-dots {
  bottom: -25px;
}

//novedades
.novedades {
  &-content {
    &_item {
      max-height: 24rem;
      padding: 2rem 1.5rem;
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }
}

//portadas
.portadas-content {
  &_titulo {
    text-align: center;
  }

  &_imagen {
    &.top {
      img {
        width: 150px;
        height: auto;
        margin: 0 auto;
        border-radius: 4px;
      }
    }
  }
}

//AMIGAS

.amigas {
  &_content {
    &-solicitud {
      &_list {
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;

        &-li {
          margin-right: .5rem;
          padding: .5rem;
          border: 1px solid $colorBorder;
          border-radius: 4px;

          &_top {
            border-radius: 4px;
            width: 75px;
            height: 75px;
            overflow: hidden;

            img {
              width: 100%;
            }
          }

          &_bottom {
            margin-top: .5rem;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            button {
              margin-bottom: .4rem;

              &:last-child {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}


//-----------------------------------///
// FORMULARIO PARA CAMBIAR DATOS PUBLICADOS  //
//-----------------------------------///
.flo  do-cambios {
  list-style-type: disc;
  padding-left: 16px;

  li {
    margin-bottom: .3rem;
  }
}

h5 {
  margin-bottom: 0;
  text-transform: uppercase;
}

.form-check-inline {
  margin-bottom: .5rem;

  .form-check-label {
    margin-left: .5rem;
  }
}

.form-group {
  label {
    font-weight: bold;
  }
}

//-----------------------------------///
// GALERIA  //
//-----------------------------------///

.instrucciones-text {
  list-style: decimal;
  padding-left: 15px;

  li {
    margin-bottom: .7rem;

    .instrucciones-text_portadas {
      list-style: square;
      padding: 0 0 0 20px;
      margin: 1rem 0 !important;

      li {
        margin-bottom: .7rem;
      }
    }

    img {
      margin: .5rem 0;
    }
  }
}

.info-galerias {
  padding-left: 14px;
  margin-top: .2rem;

  dt,
  dd {
    font-size: 11px;
    margin-bottom: 0;
  }

  dt {
    font-weight: 500;
    float: left;
    margin-right: .3rem;
  }

  dd {
    font-weight: bold;
  }
}

.img-edicion {
  width: 100%;

  @include media('<=405px', 'screen') {
    width: 242px;
  }
}

.delete {
  border: 2px dashed red;
}

.bloqueAgencia {
  width: 100%;
  padding: 1rem 0;

  select {
    background: #fff;
  }

  &_modelo {
    display: flex;
    flex-direction: row;

    &-img {
      width: 25%;

      img {
        border-radius: 4px;
        width: 100%;
      }
    }

    &-list {
      margin-left: .5rem;
      width: 75%;

      &_name {
        margin: 0;
      }

    }
  }
}

.simbologia {
  margin-bottom: 1rem;

  li {
    margin-bottom: .5rem;

    span {
      margin-right: .4rem;

    }

    span:last-child {
      font-weight: normal;
    }
  }
}

.foto-delete {
  display: inline-block;
  height: 12px;
  width: 12px;
  border: 1px dashed red;
}

.boton-subir {
  display: flex;
  align-items: center;
}

.galeria {
  .ibox-title {
    background: lighten(#cfced4, 20%) !important;

    .form-group {
      margin-bottom: 0 !important;
    }
  }
}

.herramientas {
  display: flex !important;
  justify-content: space-between;

  @include media('>=420px', 'screen') {
    justify-content: flex-start;

    div {
      margin-right: .5rem;
    }
  }
}

.ibox-heading {
  background-color: #cfced4 !important;
  padding: 10px !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  margin-bottom: 0 !important;
}

.ibox-galerias,
.ibox-portadas-opciones {
  padding: 10px;
}

.galerias,
.portadas-opciones-ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  &_li {
    margin-bottom: .8rem;

    &-content {
      background-color: #f0f8ff;
      position: relative;
      height: 141px;
      width: 93px;
      display: flex;
      align-items: center;
      margin-bottom: .2rem;

      .icheckbox_square-green {
        position: absolute;
        z-index: 1;
        right: 3%;
        background-color: #f0f8ff;
        top: 3%;
      }

      .select-img {
        position: absolute;
        top: 2%;
        right: 2%;
        z-index: 1;
      }

      .activar-photo {
        position: absolute;
        bottom: 2%;
        left: 2%;
        z-index: 1;
      }

      .li-img {
        width: 100%;
        height: auto;
        top: 0;
        left: 0;
        z-index: 0;
      }
    }
  }

  @include media('>=768px', 'screen') {
    justify-content: flex-start;

    li {
      margin-right: .8rem;
    }
  }
}


/**
 *
 * seccion portadas
 *
 */


#portadas-tab,
#ellasdicentabs,
#disponibles-tab,
#estadisticas-tab {
  flex-flow: row nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
  justify-content: space-between;
  li {
    margin-right: .5rem;
    a {
      background-color:$colorBorder;
      padding: 9px 14px;
      font-size: 12px;
      white-space: nowrap;
    }
    a.active {
      background-color: #1c84c6;
      color: white !important;
    }
  }
  border-bottom: 1px solid $colorBorder;
  padding-bottom: 1rem;
}

.portada-content {
  padding: 0 15px;

  .ibox {
    @include media('>=768px', 'screen') {
      padding: 0 15px;
    }
  }
}

.portada {
  flex-direction: column;
  align-items: center;

  &-activa {
    width: 150px;
    display: flex;
    justify-content: center;
    margin: 1rem 0;

    .portada-img {
      border: 1px solid gray;
      width: 100%;
      border-radius: 4px;
    }
  }
}

.portadas-inactivas {
  &_list {
    display: flex;
    justify-content: space-between;

    &-li {
      display: flex;
      flex-direction: column;
      width: calc(100% / 3);
      max-width: 100px;
      margin-right: .5rem;

      &_img {

        display: block;
        margin-bottom: .5rem;

        img {
          border: 1px solid gray;
          width: 100%;
          border-radius: 4px;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }

    @include media('>=768px', 'screen') {
      justify-content: flex-start;

      li {
        margin-right: .8rem;
      }
    }
  }
}

.portadas-opciones-ul {
  &_li {
    display: flex;
    flex-direction: column;
  }
}


/**
 *
 * HISTORIAS
 *
 */

.historias {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  overflow-x: scroll;
  overflow-y: hidden;

  &_li {
    display: block;
    margin-right: .5rem;
    padding: .5rem;
    border: 1px solid $colorBorder;
    border-radius: 4px;
    width: 117px;

    &-top {
      video {
        background-color: $colorBorder;
        height: 190px;
        width: 100px;
      }
    }

    &-bottom {
      .edicion-video {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
      }
    }
  }

  &.aceptadas {
    @include media('>=768px', 'screen') {
      flex-flow: row wrap;
      justify-content: space-between;
    }
  }
}

//HISTORIAS
.videos {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  overflow-x: scroll;
  overflow-y: hidden;

  &_li {
    display: block;
    margin-right: .5rem;
    padding: .5rem;
    border: 1px solid $colorBorder;
    border-radius: 4px;
    width: auto;

    &-top {
      video {
        background-color: $colorBorder;
        height: 200px;
        width: 200px;
      }
    }

    &-bottom {
      .edicion-video {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
      }
    }
  }

  &.aceptadas {
    @include media('>=768px', 'screen') {
      flex-flow: row wrap;
      justify-content: space-between;
    }
  }
}


// agencias
.agencia-publicaciones {
  display: flex;
  flex-direction: column;
  padding: 20px 0 20px 0 !important;

  &_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: .7rem 12px;
    border-bottom: 1px solid $colorBorder;

    &-left {
      $size: 40px;
      height: $size + 10px;
      width: $size;
      border-radius: 4px;
      overflow: hidden;

      img {
        width: 100%;
        height: auto;
      }
    }

    &-middle {
      margin-left: .5rem;

      .escort-exclusiva {
        color: gold;
      }

      .nombre-sitio,
      .info-publicacion {
        span {
          font-weight: bold;
        }
      }

      .nombre-sitio {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .info-publicacion {
        font-size: 12px;
      }
    }

    &-right {
      margin-left: auto;
    }

    /*     &:nth-child(even) {
      background-color: $colorBorder;
    } */
  }
}

.dias-suspendidosAgencia {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: auto;
  margin-top: .2rem;

  li {
    $sizeday: 1rem;
    background: #d0d0d0;
    display: block;
    border-radius: 2px;
    height: $sizeday;
    width: $sizeday;
    margin-right: .2rem;

    &:last-child {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .fa-check {
        font-size: .7rem;
        margin: 0 auto;
      }
    }

  }
}

.info-suspensionAgencia {
  display: flex;
  flex-flow: column nowrap;
  align-items: start;
}

.desactiva {
  filter: grayscale(100%);
}

.alerta-pantalla {
  display: none;

  @include media('<=320px', 'screen') {
    display: block;
    text-align: center;

    h5 {
      font-size: 15px;
      margin-bottom: 1rem;
    }
  }
}


/**
 *
 * P O P U L A R I D A D
 *
 */
 //ranking portadas de perfil
.ranking {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &-portada,
  &-text {
    width: calc(100% / 2);
  }
  &-portada {
    .estadisticas-perfil {
      max-width: 110px;
    }
  }

  &-text {
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  //las opciones de portada de perfil
  &_opciones {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &-portada,
    &-text {
      width: calc(100% / 2);
    }
    &-text {
      justify-content: center;
      display: flex;
      flex-direction: column;
    }
    @include media('>=576px','<1023px','screen'){
      flex-direction: column;
      &-portada,
      &-text {
        width: 100%;
        display: flex;
      }
    }
  }


  &_destacada {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-portada {
      img {
        width: 100%;
        @include media('>767px','screen'){
         max-width: 200px;
       }
      }
    }
    &-text {
      justify-content: center;
      display: flex;
      flex-direction: column;
    }
    @include media('>=576px','screen'){
      flex-direction: row;
      &-portada,
      &-text {
        width: calc(100% / 2);
        display: flex;
      }
    }
  }
}

.text-posicion {
    font-weight: bold;
    margin-top: 1rem;
    font-size: 15px;
    text-align: center;
}

.trofeo {
  display: flex;
  justify-content: center;
  i {
    font-size: 2.2rem;
    line-height: 3rem;
    margin-right: 1rem;
  }
  p {
    font-size: 3rem;
    line-height: 3rem;
  }
}


/**
 *
 * Ellas dicen
 *
 */

 #ellasdicentabs {
   border-bottom: 1px solid $colorBorder;
   padding-bottom: 1rem;
 }


 /**
  *
  * paquetes
  *
  */
  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }

.pricing {
  background-color: white;
  box-shadow: 0 1px 8px rgba(0,0,0,0.05), 0 1px 2px rgba(0,0,0,0.1);
  margin: 6px;
  border-radius: 4px;
}

 .pricing-plan {
  border-radius: 4px;
 }
 .pricing-plan li {
  padding: 10px 16px;
  border-top: 1px solid #e7eaec;
  text-align: center;
  color: #3e5476;
  font-size: .85rem;
  span {
    font-weight: 600;
  }
}

 li.pricing-title {
  background: #1ab394;
  color: #fff;
  padding: 10px;
  border-radius: 4px 4px 0 0;
  font-size: 1.8rem;
  font-weight: 600;
}

li.pricing-desc {
  font-size: 13px;
  border-top: none;
  padding: 20px 16px;
}

.precio {
  font-size: 1.8rem;
}


.listado-tutoriales {
  &_titulo {
    font-weight: 600;
  }
  li {
    margin-bottom: .6rem;
    ul {
      list-style: inside;
      li a {
        color: #3e5476 !important;
      }
    }
  }
}


.montoMaximo {
  color: red;
}

.crearPago {
  display: flex;
  flex-direction: row;
  width: 100%;
  &_portada {
    padding: 1rem;
    img {
      width: 100%;
      height: auto;
    }
    width: 30% ;
  }
  &_form {
    width: 70%;
  }
}

.bloquearAccesos {
  .fa-lock {
    color: var(--red);
  }
  .fa-unlock {
    color: var(--green);
  }
}


.notificacion {
  &_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
}

.fechaeditor {
    padding: 0;
    list-style: none;
    li {
      margin-right: .5rem;
    }
}
