
%elipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
%ul {
  margin: 0;
  list-style: none;
}


.girls {
  padding: 1rem 0 2rem 0;
  background: radial-gradient(220px 50px at center bottom, lighten($headerColor, 10%), $headerColor);
  /*  border-bottom: 1px solid lighten($headerColor, 15%); */
}



article {
  &.wrap-escort {
    float: left;
    margin-bottom: 0.625rem;
    .content-escort {
      display: flex;
      flex-flow: column nowrap;
      &__top {
        flex-grow: 2;
        position: relative;
        height: auto;

        .onCall {
          display: block;
          height: 10px;
          width: 10px;
          background-color: $verdeDisponible;
          border: 1px solid $bordeDisponible;
          position: absolute;
          top: 0.2rem;
          left: 0.3rem;
          z-index: 2;
          border-radius: 8px;
        }

        figure {
          background: lighten($headerColor, 2%);
          margin: 0;

          .background-image {
            width: 100%;
            height: 100%;
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;
            &::before {
              background: linear-gradient(to bottom, rgba(#000, 0) 70%, rgba(#000, 0.7) 100%);
              bottom: 0;
              content: "";
              display: block;
              left: 0;
              position: absolute;
              right: 0;
              top: 0;
              z-index: 1;
            }
          }
        }
      }

      &__bottom {
        /* min-height: 40px; */
        flex-grow: 1;

        figcaption {
          background-color: $gray-100;
          border-radius: 0 0 4px 4px;
          padding: 0 10px 4px;
          /* min-height: 40px; */
          height: auto;

          .wrapData {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            position: relative;
            .dataProfile {
              @extend %ul;
              font-family: $textSite;
              & li {
                //font-family: $titleGirl;
                color: lighten($headerColor, 10%);
                font-size: 0.7rem;
                line-height: 1rem;
                font-weight: 600;
                &:last-of-type {
                  text-transform: capitalize;
                  @extend %elipsis;
                }
              }
            }

            .dataIcon {
              margin: -5px 0;
              div {
                height: 1rem;
                > i {
                  color: lighten($headerColor, 10%);
                  font-size: 1rem;
                }
                > i.fa-video {
                  //clase solo para el icono de video
                  font-size: 0.9rem;
                  margin-left: 0.2rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

.wrapData {
  .t30 {
    margin-top: 0.7rem;
    text-align: center;
    div:first-child {
      font-weight: bold;
      font-size: 1rem;
      line-height: 1rem;
      margin-bottom: 0.3rem;
    }
    div:last-child {
      font-size: 0.8rem;
      line-height: 0.8rem;
      margin-bottom: 0.7rem;
    }
    &:last-child {
      div:first-child {
        color: $colordefault;
      }
    }
  }
}

.post30 {
  position: absolute;
  background-color: $colordefault;
  top: 5rem;
  left: 0.2rem;
  width: $circulo;
  height: $circulo;
  border-radius: $circulo;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  p {
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.5rem;
    margin-bottom: 0;
  }
}

.name {
  font-family: $titleGirl;
  font-weight: 400;
  color: #fff;
  position: absolute;
  bottom: 1px;
  z-index: 2;
  width: 100%;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0px 0px 3px $black;
  @extend %elipsis;
  &.exclusive {
    &::before,
    &::after {
      font-size: 0.7rem;
      content: "★";
      vertical-align: top;
      margin: 0 3%;
      color: $gold;
      text-shadow: 0px 0px 2px $black;
    }
  }
}

.sectionProfile {
  color: $gold;
  font-weight: 600;
  font-size: 0.8rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  margin: 0;
  @extend %elipsis;
}

.previewGirl {
  background: transparent;
  border: 0;
  position: absolute;
  right: 0.3rem;
  top: 0.3rem;
  border-radius: 4px;
  height: 25px;
  width: 25px;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  .icon-ojo {
    height: .9rem;
    width: .9rem;
  }
}
.data-call {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
}
.callme {
  background-color: $bordeDisponible;
  width: 60px;
  height: 30px;
  z-index: 2;
  border-radius: 20px;
  margin: 0 auto;
  position: relative;
  //trae los iconos
  @include iconcenter(#fff, 1rem);
  
  &:first-child {
    margin-right: 0.3rem;
  }
  @include media('<=320px','screen', 'portrait'){
    width: 45px;

    .icon-fono-2,
    .icon-wsp {
      width: 1rem;
      height: 1rem;
    }
  }
}



//destacadas
.big-escort {
  width: 100%;
  height: auto;
  margin-bottom: 0.8rem !important;
  .name {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }

  figure {
    height: 200px;
    .background-image {
      background-position-x: 50%;
         background-position-y: top;
         background-size: cover;
         border-radius: 4px 4px 0 0;
         width: 100%;
         height: 100%;
         display: block;
         transition: 0.5s;
    }
    @include media(">phone", ">tablet", "screen", "landscape") {
      height: 162px;
    }
  }

  .wrapData {
    padding: 0.1rem 0;
  }
  .sectionProfile {
    font-size: 0.9rem !important;
  }
}

//primer bloque
.top-escort {
  width: calc((100% / 2) - 5px);
  margin-right: 0.625rem; //10px
  .name {
    font-size: 1.3rem;
    line-height: 1.3rem;
  }
  figure {
    height: 284px;
  }
  &__section {
    width: calc((100% / 2) - 5px);
    margin-right: 0.625rem; //10px
    .name {
      font-size: 1.3rem;
      line-height: 1.3rem;
    }
    figure {
      height: 280px;
    }
  }
  //solo para el telefono
  @include media(">=phone", "<tablet", "portrait") {
    width: calc((100% / 2) - 5px);
    &:nth-child(2n) {
      margin-right: 0;
    }
    &__section {
      width: calc((100% / 2) - 5px);
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
  @include media(">=phone", "<=tablet", "landscape") {
    width: calc((100% / 3) - 7px);
    &:nth-child(3n) {
      margin-right: 0;
    }
    &__section {
      width: calc((100% / 3) - 7px);
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
  //solo ipad
  @include media(">tablet", "<=desktop") {
    width: calc((100% / 3) - 7px);
    &:nth-child(3n) {
      margin-right: 0;
    }
    & figure {
      height: 380px;
    }
    &__section {
      width: calc((100% / 3) - 7px);
      &:nth-child(3n) {
        margin-right: 0;
      }
      & figure {
        height: 380px;
      }
    }
  }
  @include media(">=tablet", "<desktop", "portrait") {
    width: calc((100% / 3) - 7px);
    &:nth-child(3n) {
      margin-right: 0;
    }
    & figure {
      height: 380px;
    }

    &__section {
      width: calc((100% / 3) - 7px);
      &:nth-child(3n) {
        margin-right: 0;
      }
      & figure {
        height: 380px;
      }
    }
  }
  @include media(">tablet", "<=desktop") {
    width: calc((100% / 3) - 7px);
    &:nth-child(3n) {
      margin-right: 0;
    }
    & figure {
      height: 380px;
    }

    &__section {
      width: calc((100% / 3) - 7px);
      &:nth-child(3n) {
        margin-right: 0;
      }
      & figure {
        height: 380px;
      }
    }
  }
  //Computador y mas
  @include media(">desktop", "<=hd") {
    width: calc((100% / 4) - 8px);
    &:nth-child(4n) {
      margin-right: 0;
    }
    & figure {
      height: 450px;
    }
    &__section {
      width: calc((100% / 4) - 8px);
      &:nth-child(4n) {
        margin-right: 0;
      }
      & figure {
        height: 450px;
      }
    }
  }
}

.middle-escort {
  width: calc((100% / 3) - 7px);
  height: auto;
  margin-right: 0.625rem;
  .name {
    font-size: 1.1rem;
    line-height: 1.1rem;
  }
  figure {
    height: 284px;
  }
  .sector-port {
    max-width: 80px;
  }
  &__section {
    margin-right: 0.625rem;
    .name {
      font-size: 1.1rem;
      line-height: 1.1rem;
    }
    figure {
      height: 280px;
    }
    .sector-port {
      max-width: 80px;
    }
  }

  //desde 320px hasta 767px portrait
  @include media(">=phone", "<tablet") {
    width: calc((100% / 3) - 7px);
    &:nth-child(3n) {
      margin-right: 0;
    }
    &__section {
      width: calc((100% / 3) - 7px);
      &:nth-child(3n) {
        margin-right: 0;
      }
      & figure {
        height: 380px;
      }
    }
  }

    //desde 320px hasta 767px landscape
    @include media(">=phone", "<tablet", 'landscape') {
      width: calc((100% / 6) - 7px);
      &:nth-child(6n) {
        margin-right: 0;
      }
      &__section {
        width: calc((100% / 6) - 7px);
        &:nth-child(6n) {
          margin-right: 0;
        }
        & figure {
          height: 380px;
        }
      }
    }

  //desde 768px hasta 1024px
  @include media(">=tablet", "<=desktop") {
    width: calc((100% / 6) - 9px);
    &:nth-child(6n) {
      margin-right: 0;
    }
    & figure {
      height: 380px;
    }
    &__section {
      width: calc((100% / 5) - 8px);
      &:nth-child(5n) {
        margin-right: 0;
      }
      & figure {
        height: 380px;
      }
    }
  }

  //desde 1024px hasta lo más grande
  @include media(">desktop") {
    width: calc((100% / 8) - 9px);
    &:nth-child(8n) {
      margin-right: 0;
    }
    & figure {
      height: 450px;
    }
    &__section {
      width: calc((100% / 6) - 8.5px);
      &:nth-child(6n) {
        margin-right: 0;
      }
      & figure {
        height: 450px;
      }
    }
  }

}

.bottom-escort {
  width: calc((100% / 3) - 7px);
  height: auto;
  margin-right: 0.625rem;

  .content-escort__top {
    figure {
      height: 111.5px;
      .background-image {
        background-position-x: center;
        background-position-y: 0%;
      }
    }
    .name {
      font-size: 1rem;
      line-height: 1rem;
    }
  }
  .sector-port {
    max-width: 80px;
  }

  &__section {
    width: calc((100% / 3) - 7px);
    height: auto;
    margin-right: 0.625rem;

    .content-escort__top {
      figure {
        &.little {
          height: 140px;
          .background-image {
            background-position-x: center;
            background-position-y: 0%;
          }
        }
      }
      .name {
        font-size: 1rem;
        line-height: 1rem;
      }
    }
    .sector-port {
      max-width: 80px;
    }
  }

    //desde 320px hasta 767px
    @include media(">=phone", "<tablet") {
      width: calc((100% / 3) - 7px);
      &:nth-child(3n) {
        margin-right: 0;
      }
      &__section {
        width: calc((100% / 3) - 7px);
        &:nth-child(3n) {
          margin-right: 0;
        }
        & figure {
          height: 380px;
        }
      }
    }

        //desde 320px hasta 767px landscape
        @include media(">=phone", "<tablet", 'landscape') {
          width: calc((100% / 6) - 7px);
          &:nth-child(6n) {
            margin-right: 0;
          }
          &__section {
            width: calc((100% / 6) - 7px);
            &:nth-child(6n) {
              margin-right: 0;
            }
            & figure {
              height: 380px;
            }
          }
        }
  
    //desde 768px hasta 1024px
    @include media(">=tablet", "<=desktop") {
      width: calc((100% / 6) - 9px);
      &:nth-child(6n) {
        margin-right: 0;
      }
      & figure {
        height: 380px;
      }
      &__section {
        width: calc((100% / 5) - 8px);
        &:nth-child(5n) {
          margin-right: 0;
        }
        & figure {
          height: 380px;
        }
      }
    }
  
    //desde 1024px hasta lo más grande
    @include media(">desktop") {
      width: calc((100% / 8) - 9px);
      &:nth-child(8n) {
        margin-right: 0;
      }
      & figure {
        height: 450px;
      }
      &__section {
        width: calc((100% / 6) - 8.5px);
        &:nth-child(6n) {
          margin-right: 0;
        }
        & figure {
          height: 450px;
        }
      }
    }

}


.sector-port {
  max-width: 80px;
}