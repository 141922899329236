// Basic Colors
$navy: #1ab394;       // Primary color
$dark-gray: #c2c2c2;  // Default color
$blue: #1c84c6;       // Success color
$lazur: #23c6c8;      // Info color
$yellow: #f8ac59;     // Warning color
$red: #ED5565;        // Danger color

// Various colors
$text-color: #676a6c;   // Body text
$gray: #f3f3f4;         // Background wrapper color
$light-gray: #D1DADE;   // Default label, badge
$label-badge-color: #5E5E5E;
$light-blue:#f3f6fb;

// Spinner color and margin
$spin-color: $navy;
$spin-margin: 0 auto;

//Basics
$basic-link-color: #337ab7;

// IBOX colors ( default panel colors)
$border-color: #e7eaec;     // IBox border
$ibox-title-bg:#ffffff;     // IBox Background header
$ibox-content-bg:#ffffff;   // IBox Background content

//Sidebar width
$sidebar-width: 220px;

// Boxed layout width
$boxed-width: 1200px;
$boxed-background: image-url('patterns/shattered.png');

//Border radius for buttons
$btn-border-radius: 3px;

//Navigation
$nav-bg: #2F4050;
$nav-profile-pattern: image-url("patterns/header-profile.png");
$nav-text-color: #a7b1c2;


////PROPIAS
$colordefault: #FF4343;
$textSite: 'Heebo', sans-serif;

$circulo: 3rem;

$titleGirl: 'Oswald', sans-serif;

//Heebo  https://fonts.google.com/specimen/Heebo?selection.family=Heebo:400,700
@import url('https://fonts.googleapis.com/css?family=Heebo:400,700&display=swap');
//OSWALD font para el nombre de la chica @import @import url('https://fonts.googleapis.com/css?family=Oswald:400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Oswald:400,500,600,700&display=swap');
$headerColor: #09152b; 
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$verdeDisponible:#3bdf75;
$bordeDisponible:#10bf4e;
$gold: #9e9611;